<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-02-14 10:32:02
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-30 09:38:38
 * @FilePath: /siana_admin/src/views/user/components/modal.vue
-->
<template>
  <!--弹窗-->
  <el-dialog :title="title"
    :visible.sync="dialogVisible"
    width="500px"
    :fullscreen="phone"
    :close-on-click-modal="false"
    :before-close="handleClose"
    class="upload_box">
    <!--form-->
    <el-form ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :label-position="position"
      label-width="100px">
      <el-form-item label="姓名"
        prop="name">
        <!--下拉列表-->
        <el-input
          v-model="ruleForm.name"
          placeholder="请输入" />
      </el-form-item>
      <el-form-item
        label="手机号"
        prop="mobile">
        <!--下拉列表-->
        <el-input
          v-model="ruleForm.mobile"
          placeholder="请输入" />
      </el-form-item>
      <el-form-item label="密码"
        prop="password">
        <!--下拉列表-->
        <el-input
          v-model="ruleForm.password"
          :type="type"
          placeholder="请输入">
          <i slot="suffix"
            class="el-input__icon el-icon-view"
            @click="type == 'password'? type ='text':type ='password'"></i>
        </el-input>
      </el-form-item>
      <el-form-item
        label="剩余次数"
        prop="remainingTimes">
        <!--下拉列表-->
        <el-input
          v-model="ruleForm.remainingTimes"
          type="Number"
          placeholder="请输入" />
      </el-form-item>
    </el-form>
    <!-- 表单结束 -->
    <!--操作按钮-->
    <span slot="footer"
      class="dialog-footer">
      <el-button
        @click="handleClose">取
        消</el-button>
      <!--确定-->
      <el-button
        type="primary"
        :disabled="loading"
        @click="handleSubmit"><i
          v-if="loading"
          class="el-icon-loading"></i>确
        定</el-button>
    </span>
  </el-dialog>
  <!--弹窗 end-->
</template>

<script>
// 引入组件及方法
export default {
  name: 'Yxb3NewModal',

  data() {
    return {
      phone: false,
      type: 'password',
      // checked: false, //是否覆盖数据
      loading: false, // loading状态
      dialogVisible: false, // 弹窗显隐状态
      // 标题
      position: 'right',
      title: '', // 弹窗标题
      // 表单
      ruleForm: {
        name: '',
        mobile: '',
        password: '',
        remainingTimes: 0
      },
      // 校验规则
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        password: [{ required: false, message: '请输入密码', trigger: 'blur' }],
        remainingTimes: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },

  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    this.position = this.phone == true ? 'top' : 'right'
  },

  methods: {
    // 弹窗信息设置
    open(page, data) {
      this.phone = JSON.parse(window.localStorage.getItem('phone'))
      this.dialogVisible = true

      this.title = '编辑'
      this.ruleForm = data
    },
    getApi(api) {
      this.$https(api, this.ruleForm).then(res => {
        this.$message.success(this.title + '成功')
        this.handleClose()
        this.$emit('ok')
      })
    },
    handleClose() {
      this.ruleForm = {
        name: '',
        mobile: '',
        password: '',
        remainingTimes: 10
      }
      this.dialogVisible = false
    },
    // 提交
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.getApi('USEREDIT')
        }
      })
    }
  }
}
</script>
<style lang="scss">
.upload_box {
  .el-upload-dragger {
    width: 460px !important;
  }
}
</style>
