<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-23 14:40:12
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-30 09:23:07
 * @FilePath: /siana_admin/src/views/user/components/card.vue
-->
<template>
  <div class="mt20">
    <div
      v-if="tableData.length > 0">
      <el-card
        v-for="(item,index) in tableData"
        :key="index"
        class="mt10 cardess">
        <div slot="header"
          class="header_boxs">
          <div>操作</div>
          <div class="item">
            <el-button
              size="small"
              type="primary"
              :disabled="item.mobile === 'admin'"
              @click="$emit('edit',item)">
              编辑
            </el-button>
            <el-button
              size="small"
              type="danger"
              @click="$emit('del',item)">
              删除
            </el-button>
          </div>
        </div>
        <div
          class="card_body">
          <div
            v-for="(com,c) in columns"
            :key="c"
            class="items">
            <div
              class="label">
              {{ com.title }}：
            </div>
            <div
              class="value">
              <span
                v-if="com.key =='type'">
                <span
                  v-if="item.type=='0'">次数用户</span>
                <span
                  v-if="item.type=='1'">月卡用户</span>
                <span
                  v-if="item.type=='2'">临时用户</span>
              </span>
              <span
                v-else-if="com.key =='avatar'">
                <img
                  :src="item.avatar"
                  style="width:30px" />
              </span>
              <span
                v-else-if="com.key =='isEvent'">
                <span
                  v-if="item.isEvent=='0'">未关注</span>
                <span
                  v-if="item.isEvent=='1'">已关注</span>
              </span>
              <span v-else>
                {{ item[com.key] }}
              </span>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <el-card v-else>
      <el-empty
        description="暂无数据" />
    </el-card>
    <div class="pagination">
      <el-pagination
        style="margin-top:20px"
        :current-page="pageNumer"
        :page-size="pageSize"
        :background="true"
        layout="total, prev,pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-valid-default-prop
    tableData: { type: Array, default: [] },
    // eslint-disable-next-line vue/require-valid-default-prop
    columns: { type: Array, default: [] },
    tableClass: { type: String, default: '' },
    pageSize: { type: Number, default: 10 },
    pageNumer: { type: Number, default: 1 },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 },
    showImg: { type: Boolean, default: false },
    isSelection: { type: Boolean, default: false }
  },
  mounted() {
    this.columns.shift()
    this.columns.pop()
  },
  methods: {
    // 修改翻页
    handleSizeChange(e) {
      this.$emit('changePage', {
        pageNumer: this.pageNumer,
        pageSize: e
      })
    },
    // 设置每页条数
    handleCurrentChange(e) {
      this.$emit('changePage', {
        pageNumer: e,
        pageSize: this.pageSize
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: end;
}
.cardess {
  margin-bottom: 20px;
  .header_boxs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card_body {
    overflow: hidden;
    transition: 0.1s all ease-out;
  }
  .items {
    display: flex;
    align-items: center;
    height: 24px;
    .label {
      color: #666666;
      width: 100px;
      font-size: 13px;
      text-align: justify;
      text-justify: distribute-all-lines;
      text-align-last: justify;
      -moz-text-align-last: justify;
      -webkit-text-align-last: justify;
    }
    .value {
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      text-overflow: ellipsis;
      width: calc(100% - 100px);
    }
  }
  .footer {
    border-top: 1px solid #d9d9d9;
    padding-top: 10px;
    text-align: right;
    margin-top: 10px;
  }
}
.cards:last-child {
  margin-bottom: 0;
}
</style>
