<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2022-11-04 16:43:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-30 11:00:36
 * @FilePath: /siana_admin/src/views/user/components/list.vue
-->
<template>
  <div>

    <!-- 搜索区 -->
    <SearchForm
      :columns="columns"
      @search="search" />
    <el-card shadow="never">

      <!-- 表格标题 -->
      <div
        class="tableHeader">
        <div class="left">
          用户管理
        </div>
        <!--操作按钮-->
        <div class="right">
          <!-- <el-button
            @click="$refs.show.open('add')">添加</el-button> -->
        </div>
      </div>
      <!--
      表格组件
      @setColumns  //获取表头方法
      @selectChange // 勾选方法
      @changePage  // 切换页码方法
     -->
      <Card v-if="phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @del="del"
        @changePage="changePage" />
      <Table v-if="!phone"
        class="mt10"
        :columns="columns"
        :page-number="pageNumber"
        :page-size="pageSize"
        :total="total"
        :table-loading="tableLoading"
        :table-data="tableData"
        @edit="edit"
        @del="del"
        @giveVip="giveVip"
        @changePage="changePage" />
      <!--表格组件 end-->
      <ModalUpload ref="show" @ok="getData" />
    </el-card>



    <!-- <giveVIpDialog ref="giveVIpDialog" @ok="getData"></giveVIpDialog> -->


  </div>
</template>

<script>
// 引入组件及方法
// 引入组件
import SearchForm from './search.vue'
import Table from './table.vue'
import Card from './card.vue'
import ModalUpload from './modal.vue'
import giveVIpDialog from "./giveVIpDialog.vue"
export default {
  name: 'Yxb30uiIndex',
  components: { SearchForm, Table, ModalUpload, Card, giveVIpDialog },
  // 数据
  data() {
    return {
      phone: false,
      // 页码
      type: '',
      pageNumber: 1, // 当前页
      // 页面尺寸
      pageSize: 10, // 每页显示条数
      // 当前页
      total: 0, // 数据总数
      // 表格数据
      tableData: [], // 列表数据
      // 表格数据 加载
      tableLoading: false, // 列表加载
      // 筛选项
      queryParams: {}, // 请求参数
      // 切换
      setColumnes: [], // 表头
      // 表头
      columns: [
        {
          // 序号
          title: '序号',
          fixed: 'left',
          minwidth: 50,
          key: 'index',
          align: 'center',
          // 工具提示
          tooltop: false
        },

        {
          // 年级
          title: '头像',
          key: 'avatar',
          minwidth: 130
          // end
        },
        {
          // 年级
          title: '姓名',
          key: 'name',
          minwidth: 130
          // end
        },
        {
          // 姓名
          title: '手机号',
          key: 'mobile',
          minwidth: 130,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 姓名
          title: '浏览器指纹',
          key: 'browserFingerprint',
          minwidth: 130,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 姓名
          title: '邮件地址',
          key: 'email',
          minwidth: 130,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 姓名
          title: 'IP地址',
          key: 'ipAddress',
          minwidth: 130,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },

        {
          // 姓名
          title: '上次登录时间',
          key: 'lastLoginTime',
          minwidth: 180,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '创建时间',
          key: 'createTime',
          minwidth: 180,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '用户类型',
          key: 'type',
          minwidth: 100,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '微信用户',
          key: 'fromUserName',
          minwidth: 180,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '关注公众号',
          key: 'isEvent',
          minwidth: 130,
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        },
        {
          // 层次
          title: '操作',
          key: 'actions',
          minwidth: 250,
          fixed: 'right',
          // 工具提示
          tooltop: true,
          isShow: true
          // end
        }
      ],
      giveVIpDialogVisible:false
    }
  },
  //  初始化
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    // 数据初始化
    this.getData()
  },

  methods: {
    // 获取数据
    getData() {
      this.$https('USERLIST', {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        ...this.queryParams
      }).then(res => {
        this.tableData = res.data.records
        this.total = parseInt(res.data.total)
      })
    },
    // 打开编辑弹窗
    edit(row) {
      this.$refs.show.open('edit', row)
    },
    // 删除项
    del(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$https('USERDELETE', { ids: [row.id] }).then(res => {
          this.getData()
          this.$message.success('删除成功')
        })
      })
    },
    //赠送会员
    giveVip(row){
      
      // this.$refs.giveVIpDialog.open(row.id)


      this.$confirm('确定要送他会员吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        var res = await this.$https.get('give_FREE_DAYS', { userId: row.id })

        if(res.status == 200){
          this.$message({
            type: 'success',
            message: `赠送成功`
          });
        }
      }).catch(async () => {
                 
      });



      // this.$alert(' 确定要送他会员吗', '', {
      //     confirmButtonText: '确定',
      //     callback: async action => {


      //       var res = await this.$https.get('give_FREE_DAYS', { userId: row.id })

      //       if(res.status == 200){
      //         this.$message({
      //           type: 'success',
      //           message: `赠送成功`
      //         });
      //       }
            


      //     }
      //   });


    },
    // 翻页
    changePage(data) {
      this.pageNumber = data.pageNumber
      this.pageSize = data.pageSize
      this.getData()
    },
    // 搜索
    search(data) {
      this.pageNumber = 1
      this.queryParams = data
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped></style>
