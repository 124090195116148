<template>
    <!--弹窗-->
    <el-dialog title="title"
      :visible.sync="dialogVisible"
      width="520px"
      :fullscreen="phone"
      :close-on-click-modal="false"
      :before-close="handleClose"
      v-loading.fullscreen.lock="loading"
      class="upload_box">

      <!--form-->
      <!-- <el-form ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        :label-position="position"
        label-width="120px">


            <el-form-item label="赠送天数" prop="count">
                <el-input v-model.number="ruleForm.count" placeholder="请输入" />
            </el-form-item>


      </el-form> -->

      <!--操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <!--确定-->
        <el-button type="primary" :disabled="loading" @click="handleSubmit">
            <i v-if="loading" class="el-icon-loading"></i>
            确 定
        </el-button>
      </span>



    </el-dialog>
    <!--弹窗 end-->
  </template>
  
  <script>
  // 引入组件及方法
  export default {
    name: 'Yxb3NewModal',
  
    data() {

        var checkAge = (rule, value, callback) => {
            if (!value) {
            return callback(new Error('不能为空'));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'));
                } else {
                    if (value <= 0) {
                        callback(new Error('数字必须大于0'));
                    } else {
                        callback();
                    }
                }
            }, 1000);
        };



      return {
        phone: false,
        type: 'numberTimes',
        loading: false, // loading状态
        dialogVisible: false, // 弹窗显隐状态
        // 标题
        position: 'right',
        title: '', // 弹窗标题
        userId:"",
        // 表单
        ruleForm: {
          count: '',
        },
        // 校验规则
        rules: {
            count: [{ validator: checkAge, trigger: 'blur' }],
          // price: [{ required: true, message: '请输入', trigger: 'change' }],
          // numberTimes: [{ required: true, message: '请输入', trigger: 'change' }],
          // sort: [{ required: true, message: '请输入', trigger: 'change' }],
          // stock: [{ required: true, message: '请输入', trigger: 'change' }]
        }
      }
    },
  
    //  初始化
    mounted() {

    },
  
    methods: {

        open(userId) {
            this.userId = userId
            this.dialogVisible = true
        },

      getApi(api) {
        this.$https.get(api, this.ruleForm).then(res => {
          this.$message.success(this.title + '成功')
          this.handleClose()
          this.$emit('ok')
        })
      },
      handleClose() {
        this.ruleForm = {
          count: ''
        }
        this.dialogVisible = false
      },
      // 提交
      async handleSubmit() {
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {

            this.loading = true
            
            await this.$https.get('give_FREE_DAYS', this.ruleForm)
            this.loading = false

            this.$message.success('赠送成功')
            this.handleClose()
            this.$emit('ok')

           
          }
        })
      }
    }
  }
  </script>
  <style lang="scss">
  .upload_box {
    .el-upload-dragger {
      width: 460px !important;
    }
  }
  </style>
  